import React, { useEffect, useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import client from './feathers';

import Map from './components/Map';

import LTETowers from './LTE.json';

const coordinatesService = client.service('coordinates');

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

const ControlWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;
`;

const Box = styled.div`
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 14px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const BoxRow = styled.div`
  margin: 10px 0;
`;

const mapStyles = [
  {
    name: 'satellite-v9',
    title: 'Satellit',
  },
  {
    name: 'light-v10',
    title: 'Hell',
  },
  {
    name: 'dark-v10',
    title: 'Dunkel',
  },
  {
    name: 'streets-v11',
    title: 'Straßen',
  },
  {
    name: 'outdoors-v11',
    title: 'Landschaft',
  },
];

const App = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [firstDate, setFirstDate] = useState(null);
  const [mapStyle, setMapStyle] = useState(mapStyles[0].name);
  const [data, setData] = useState([]);
  const [seconds, setSeconds] = useState(0);
  const [follow, setFollow] = useState(true);
  const [lteGeoJSON, setLteGeoJSON] = useState({});
  const [lteCurrentSite, setLteCurrentSite] = useState('78327');
  const [lteConnectionRoute, setLteConnectionRoute] = useState([]);
  const [showLTE, setShowLTE] = useState(false);

  // const coordinates = [];

  const coordinates = useMemo(
    () =>
      data.map(curr => [
        curr.location.coordinates[1],
        curr.location.coordinates[0],
      ]),
    [data],
  );

  const getFirstDate = async () => {
    try {
      const result = await coordinatesService.find({
        query: {
          $limit: 1,
          $sort: {
            date: 1,
          },
        },
      });

      const bla = new Date(result.data[0].date);

      bla.setHours(0, 0, 0, 0);

      setFirstDate(bla);
    } catch (e) {
      console.log(e);
    }
  };

  const loadData = useCallback(async () => {
    try {
      await client.authenticate({
        strategy: 'local',
        email: 'erika.schmalz@outlook.com',
        password: '12345678',
      });

      getFirstDate();

      const date = new Date(startDate);
      date.setHours(0, 0, 0, 0);

      console.log(date.toISOString());

      const result = await coordinatesService.find({
        query: {
          $limit: 9999999,
          date: {
            $gte: date.getTime(),
          },
          $sort: {
            date: -1,
          },
        },
      });

      setData(result.data);
      setSeconds(
        Math.round(
          (new Date().getTime() - new Date(result.data[0].date).getTime()) /
            1000,
        ),
      );

      // console.log(result.data);
    } catch (e) {
      console.log(e);
    }
  }, [startDate]);

  const handleUpdate = message => {
    console.log('got update');
    setData(current => [message, ...current]);
    setSeconds(0);
  };

  useEffect(() => {
    // console.log(LTETowers);

    const test = LTETowers.filter(tower => tower.visible);

    setLteGeoJSON({
      type: 'FeatureCollection',
      features: test.map(tower => {
        return {
          // feature for Mapbox DC
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [tower.longitude, tower.latitude],
          },
          // properties: {
          //   title: 'Mapbox DC',
          // },
        };
      }),
    });
  }, []);

  useEffect(() => {
    if (data.length === 0) {
      return;
    }

    const lteCurrentCoords = LTETowers.find(
      tower => tower.siteID === lteCurrentSite,
    );

    setLteConnectionRoute([
      [data[0].location.coordinates[1], data[0].location.coordinates[0]],
      [lteCurrentCoords.longitude, lteCurrentCoords.latitude],
    ]);
  }, [data, lteCurrentSite]);

  useEffect(() => {
    console.log('App mounted (finished rendering)');

    loadData();

    coordinatesService.on('created', handleUpdate);

    const timer = setInterval(() => {
      setSeconds(s => s + 1);
    }, 1000);

    return () => {
      console.log('App unmounted');

      clearInterval(timer);

      coordinatesService.removeListener('created', handleUpdate);
    };
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData, startDate]);

  const onStyleSelect = e => {
    console.log('onStyleSelect');
    setMapStyle(e.target.value);
  };

  const onFollowChange = e => {
    setFollow(c => !c);
  };

  const onShowLTEChange = e => {
    setShowLTE(c => !c);
  };

  console.log('render app');
  // console.log(data);
  // console.log(coordinates);

  let lteSignal = '';

  if (data[0]?.lteRsrp <= -120) {
    lteSignal = '';
  } else if (data[0]?.lteRsrp <= -111) {
    lteSignal = '*';
  } else if (data[0]?.lteRsrp <= -101) {
    lteSignal = '**';
  } else if (data[0]?.lteRsrp <= -91) {
    lteSignal = '***';
  } else if (data[0]?.lteRsrp <= -81) {
    lteSignal = '****';
  } else if (data[0]?.lteRsrp >= -80) {
    lteSignal = '*****';
  }

  return (
    <Wrapper>
      <ControlWrapper>
        <Box>
          Geschwindigkeit: {Number(data[0]?.speed).toFixed(2)} km/h
          <br />
          Höhe über N. N.: {Number(data[0]?.altitude).toFixed(2)} m<br />
          Satelliten: {data[0]?.satellites}
          <br />
          LTE Anbieter: {data[0]?.lteOperator}
          <br />
          LTE Signal: {lteSignal} ({data[0]?.lteRsrp} dBm)
          <br />
          LTE Mast: {lteCurrentSite}
          <br />
          <br />
          Letztes Update: {seconds}s
          <br />
        </Box>
        <Box>
          <BoxRow>
            <select onChange={onStyleSelect} value={mapStyle}>
              {mapStyles.map(style => (
                <option key={style.name} value={style.name}>
                  {style.title}
                </option>
              ))}
            </select>
          </BoxRow>
          <BoxRow>
            <label>
              <input
                type="checkbox"
                checked={follow}
                onChange={onFollowChange}
              />
              Verfolgen
            </label>
          </BoxRow>
          <BoxRow>
            Startdatum:{' '}
            <DatePicker
              minDate={firstDate}
              dateFormat="dd.MM.yyyy"
              selected={startDate}
              onChange={date => setStartDate(date)}
            />
          </BoxRow>
          <BoxRow>
            <label>
              <input
                type="checkbox"
                checked={showLTE}
                onChange={onShowLTEChange}
              />
              LTE
            </label>
          </BoxRow>
        </Box>
      </ControlWrapper>
      <Map
        mapStyle={mapStyle}
        route={coordinates}
        follow={follow}
        lte={(showLTE && lteGeoJSON) || {}}
        lteConnectionRoute={(showLTE && lteConnectionRoute) || []}
      />
    </Wrapper>
  );
};

export default App;
