import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken =
  'pk.eyJ1IjoiYWVpY2hob3JuIiwiYSI6ImNsNm1qdTF0bzA0cHczY25sNWUweHJwZGUifQ.b7TXYNuURgIVQte7ux5PFA';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const start = {
  center: [80, 36],
  zoom: 2,
  pitch: 0,
  bearing: 0,
};

const carRoute = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    },
  ],
};

const lteRoute = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    },
  ],
};

const carLocation = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [],
      },
    },
  ],
};

const Map = ({
  mapStyle = 'satellite-v9',
  route = [],
  follow = true,
  lte = {},
  lteConnectionRoute = [],
}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const mounted = useRef(false);
  const firstLoad = useRef(true);
  const finishedFlyTo = useRef(false);

  const flyTo = (lat, lon) => {
    console.log('flyTo');

    const target = {
      // center: [74.5, 40],
      // zoom: 2
      center: [lat, lon],
      zoom: 13,
      // bearing: 130,
      // pitch: 75
    };

    map.current.flyTo({
      ...target, // Fly to the selected target
      duration: 10000, // Animate over 12 seconds
      essential: true, // This animation is considered essential with
      //respect to prefers-reduced-motion
    });
  };

  const updateMapData = useCallback(() => {
    console.log('updateMapData');

    if (!route || route.length === 0 || !map.current) return;

    carRoute.features[0].geometry.coordinates = route;
    map.current.getSource('trace').setData(carRoute);

    carLocation.features[0].geometry.coordinates = route[0];
    map.current.getSource('iss').setData(carLocation);

    map.current.getSource('lte').setData(lte);
    // console.log(lte);

    lteRoute.features[0].geometry.coordinates = lteConnectionRoute;
    map.current.getSource('lteRoute').setData(lteRoute);
    console.log(lteRoute);

    if (firstLoad.current) {
      firstLoad.current = false;
      flyTo(route[0][0], route[0][1]);
      setTimeout(() => {
        finishedFlyTo.current = true;
      }, 10000);
    } else if (follow && finishedFlyTo.current) {
      map.current.panTo(route[0]);
    }
  }, [route, lte, lteConnectionRoute, follow]);

  const onStyleLoad = () => {
    console.log('style.load');
    map.current.setFog({ 'horizon-blend': 0.05 });

    try {
      map.current.addSource('mapbox-dem', {
        type: 'raster-dem',
        url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
        tileSize: 512,
        maxzoom: 14,
      });
      // add the DEM source as a terrain layer with exaggerated height
      map.current.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });

      map.current.addSource('trace', {
        type: 'geojson',
        data: null,
      });

      map.current.addLayer({
        id: 'trace',
        type: 'line',
        source: 'trace',
        paint: {
          'line-color': 'red',
          'line-opacity': 0.75,
          'line-width': 5,
        },
      });

      map.current.addSource('lteRoute', {
        type: 'geojson',
        data: null,
      });

      map.current.addLayer({
        id: 'lteRoute',
        type: 'line',
        source: 'lteRoute',
        paint: {
          'line-color': 'green',
          'line-opacity': 0.75,
          'line-width': 5,
        },
      });

      map.current.addSource('iss', {
        type: 'geojson',
        data: null,
      });

      map.current.addLayer({
        id: 'iss',
        type: 'symbol',
        source: 'iss',
        layout: {
          'icon-image': 'car-15',
        },
      });

      map.current.addSource('lte', {
        type: 'geojson',
        data: lte,
      });

      // Add a symbol layer
      map.current.addLayer({
        id: 'lte',
        type: 'symbol',
        source: 'lte',
        layout: {
          'icon-image': 'triangle-15',
          // 'icon-image': 'custom-marker',
          // get the title name from the source's "title" property
          // 'text-field': ['get', 'title'],
          // 'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          // 'text-offset': [0, 1.25],
          // 'text-anchor': 'top',
        },
        paint: {
          'icon-color': '#00ff00',
          'icon-halo-color': '#fff',
          'icon-halo-width': 2,
        },
      });

      updateMapData();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    console.log('Map rendered');

    if (!map.current) {
      console.log('initialize mapbox');

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: `mapbox://styles/mapbox/${mapStyle}`,
        // center: [lng, lat],
        // zoom: zoom,
        projection: 'globe',
        ...start,
      });
    }

    console.log('set map event listeners');

    map.current.on('style.load', onStyleLoad);

    return () => {
      console.log('unset map event listeners');
      map.current.off('style.load', onStyleLoad);
    };
  });

  useEffect(() => {
    if (!map.current || !mounted.current) {
      return;
    }

    console.log('map style changed!');
    map.current.setStyle('mapbox://styles/mapbox/' + mapStyle);
  }, [mapStyle]);

  useEffect(() => {
    console.log('route updated');
    updateMapData();

    /*
    if (!firstLoad.current && follow) {
      map.current.panTo(route[0]);
    }
    */
  }, [route, lteConnectionRoute, updateMapData]);

  useEffect(() => {
    console.log('Map mounted (finished rendering)');

    mounted.current = true;

    return () => {
      console.log('Map unmounted');
      mounted.current = false;
    };
  }, []);

  /*
  useEffect(() => {
    map.current.off('style.load');

    map.current.on('style.load', () => {
      console.log('style.load');
      map.current.setFog({ 'horizon-blend': 0.05 });
    });

    return () => {
      map.current.off('style.load');
    };
  });
  */

  console.log('render map');
  console.log(lteConnectionRoute);

  return (
    <Wrapper>
      <MapContainer ref={mapContainer} />
    </Wrapper>
  );
};

export default Map;
