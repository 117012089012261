import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';

const { REACT_APP_API } = process.env;

const socket = io(REACT_APP_API);
const client = feathers();

client.configure(socketio(socket));

client.configure(
  auth({
    storageKey: 'auth'
  })
);

export default client;
